import { pluck, uniq } from 'ramda';

import { categories } from './strings';

const shows = [
  {
    id: 'baumhaus',
    topic: 'Baumhaus',
    channel: 'kika',
    category: 'kids',
    durationMax: 6,
  },
  {
    id: 'sandmann',
    topic: 'Sandmännchen',
    channel: 'kika',
    category: 'kids',
    durationMin: 6,
    // limit: 7,
    everywhere: true,
    future: false,
    filters: [{
      property: 'title',
      match: /^((?!sorbisch).)*$/,
    }],
    transforms: [
      {
        property: 'title',
        match: /: /,
        replace: ' - ',
      },
    ],
    downloadTitle: [
      'topic',
      'title',
    ],
  },
  {
    id: 'shaunschaf',
    topic: 'Shaun das Schaf',
    channel: 'kika',
    category: 'kids',
    durationMin: 7,
    // sortBy: 'title',
    // sortOrder: 'asc',
  },
  {
    id: 'sesamstrasse',
    display: 'Sesamstraße',
    topic: 'Das Magazin',
    channel: 'kika',
    category: 'kids',
    duration: 20,
    future: false,
  },
  {
    id: 'logo',
    topic: 'logo!',
    channel: 'kika',
    category: 'kids',
    sortBy: 'date',
    sortOrder: 'desc',
  },
  // {
  //   id: 'robinhood',
  //   topic: 'Robin Hood',
  //   category: 'kids',
  //   sortBy: 'title',
  // },
  {
    id: 'janhenry',
    topic: 'Ein Fall für die Erdmännchen',
    channel: 'kika',
    category: 'kids',
    sortBy: 'title',
    reverse: true,
  },
  // {
  //   id: 'kennetier',
  //   topic: 'Ich kenne ein Tier',
  //   channel: 'kika',
  //   category: 'kids',
  //   durationMin: 7,
  //   sortBy: 'title',
  //   sortOrder: 'asc',
  // },
  {
    id: 'loewenzahn',
    topic: 'Löwenzahn',
    channel: 'kika',
    category: 'kids',
    future: false,
  },
  {
    id: 'sendungmaus',
    topic: 'Die Sendung mit der Maus',
    channel: 'ard',
    category: 'kids',
  },
  // {
  //   id: 'sendungelefant',
  //   topic: 'Die Sendung mit dem Elefanten',
  //   channel: 'kika',
  //   category: 'kids',
  //   durationMin: 20,
  // },
  // {
  //   id: 'maulwurf',
  //   title: 'Maulwurf',
  //   topic: 'Die Maus',
  //   channel: 'wdr',
  //   category: 'kids',
  //   downloadTitle: [
  //     'title',
  //   ],
  // },
  {
    id: 'petterssonfindus',
    topic: 'Pettersson und Findus',
    channel: 'kika',
    category: 'kids',
    sortBy: 'title',
    sortOrder: 'asc',
    durationMax: 13,
  },
  {
    id: 'planetschule',
    topic: 'Planet Schule',
    channel: 'swr',
    category: 'kids',
  },
  // {
  //   id: 'pinguinloewe',
  //   topic: 'Pinguin, Löwe & Co.',
  //   channel: 'ard',
  //   category: 'kids',
  // },
  {
    id: 'leopardseebaer',
    topic: 'Leopard, Seebär & Co.',
    channel: 'ndr',
    category: 'kids',
  },
  {
    id: 'pandagorilla',
    topic: 'Panda, Gorilla & Co.',
    channel: 'ard',
    category: 'kids',
  },
  {
    id: 'giraffeerdmaennchen',
    topic: 'Giraffe, Erdmännchen & Co.',
    channel: 'ard',
    category: 'kids',
  },
  {
    id: 'elefanttiger',
    topic: 'Elefant, Tiger & Co.',
    channel: 'ard',
    category: 'kids',
  },
  {
    id: 'tiere',
    topic: 'Tiere',
    channel: '3sat',
    category: 'kids',
    sortBy: 'title',
    limit: 1000,
  },
  // {
  //   id: 'killingeve',
  //   topic: 'Killing Eve',
  //   category: 'adult',
  //   filters: [{
  //     property: 'title',
  //     match: /^((?!(Englisch)).)*$/,
  //   }],
  //   sortBy: 'season',
  // },
  // {
  //   id: 'blockbustaz',
  //   topic: 'Blockbustaz',
  //   channel: 'zdf',
  //   category: 'fun',
  //   filters: [{
  //     property: 'title',
  //     match: /\(.*\/.*\)/,
  //   }],
  // },
  // {
  //   id: 'kesslerexpedition',
  //   topic: 'Kesslers Expedition',
  //   channel: 'rbb',
  //   category: 'fun',
  //   sortBy: 'title',
  //   transforms: [{
  //     property: 'title',
  //     match: 'Foge',
  //     replace: 'Folge',
  //   }, {
  //     property: 'title',
  //     match: /Folge .*: /,
  //     replace: '',
  //   }],
  // },
  // {
  //   id: 'heuteshow',
  //   topic: 'heute-show',
  //   title: 'Nachrichtensatire',
  //   channel: 'zdf',
  //   category: 'fun',
  //   transforms: [{
  //     property: 'title',
  //     match: ' - Nachrichtensatire mit Oliver Welke',
  //     replace: '',
  //   }],
  //   downloadTitle: [
  //     'title',
  //   ],
  // },
  {
    id: '37grad',
    topic: '37 Grad',
    channel: 'zdf',
    category: 'people',
  },
  {
    id: 'dokureportage',
    topic: 'Doku und Reportage',
    channel: 'rbb',
    category: 'people',
  },
  {
    id: 'rbbpraxis',
    topic: 'rbb PRAXIS',
    channel: 'rbb',
    category: 'health',
  },
  {
    id: 'visite',
    topic: 'Visite',
    channel: 'ard',
    category: 'health',
  },
  {
    id: 'aktivgesund',
    topic: 'aktiv und gesund',
    channel: 'br',
    category: 'health',
  },
  {
    id: 'telegym',
    topic: 'Tele-Gym',
    channel: 'br',
    category: 'health',
  },
  {
    id: 'querbeet',
    topic: 'Querbeet',
    channel: 'br',
    category: 'hobby',
  },
  {
    id: 'schnittgut',
    topic: 'Schnittgut',
    channel: 'br',
    category: 'hobby',
  },
  {
    id: 'planetwissen',
    topic: 'Planet Wissen',
    channel: 'br',
    category: 'science',
  },
  {
    id: 'terrax',
    topic: 'Terra X',
    channel: 'zdf',
    category: 'science',
  },
  {
    id: 'alphacentauri',
    topic: 'alpha-Centauri',
    channel: 'br',
    category: 'science',
  },
  {
    id: 'spacenight',
    topic: 'Space Night',
    channel: 'br',
    category: 'science',
    sortBy: 'title',
  },
];

const getSearchShow = id => ({
  id: '',
  title: id,
  sortBy: 'date',
});

export const getConfig = id =>
  (id && (shows.find(show => show.id === id) || getSearchShow(id))) || shows[0];

export const getCategoriesWithShows = () =>
  uniq(pluck('category', shows))
    .map(category => ({
      id: category,
      title: categories[category],
      shows: shows.filter(show => !show.hidden).filter(show => show.category === category),
    }));
